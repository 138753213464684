
    .new-line {
        display: inline;
        white-space: initial;
        word-break: break-all
    }
    .colorTag{
        color: red;
    }
    .dot {
        height: 10px;
        width: 10px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      }
      .nav-item {
        position: relative !important;
        display: inline-block;
      }
      .notification-badge {
        background-color: red;
        color: white;
        border-radius: 50%;
        padding: 5px 10px;
        font-size: 12px;
        position: absolute;
        font-weight: 700;
        top: 0px;
        right: 0px;
      }
      .notification-badge-individual {
        background-color: red;
        margin-top: 50px;
        display: inline-block;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 25px;
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 700;
      }
      
      /* tab  */

      .tabs {
        display: flex;
      }
      
      .tab {
        padding: 10px 20px;
        border: none;
        background-color: #ccc;
        cursor: pointer;
        font-weight: bold;
      }
      
      .tab.active {
        background-color: white;
      }
      
      .tab-content {
        display: none;
        padding: 20px;
        border: 1px solid #ccc;
      }
      
      .tab-content.active {
        display: block;
      }
      
      .tab-content h2 {
        margin-top: 0;
      }
      
      .tab-content p {
        margin-bottom: 0;
      }

      /* popup  */

    

      .delete_overLay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
      }
      
      .delete-alert-box {
        position: fixed; /* Keep as fixed */
      
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        background-color: rgb(22, 60, 99);
        padding: 2rem;
        border-radius: 0.5rem;
        box-shadow: 0 0.5rem 1rem rgba(116, 112, 112, 0.3);
        z-index: 1000;
        text-align: center;
        color: white; /* Set text color to white or any other color you prefer */
      }
    .alert-box-updateOrder {
        position: fixed;
        overflow-y: scroll;
        top: 50%;
        left: 50%;
       width: 80%;
       max-height:90% ;
        transform: translate(-50%, -50%);
        /* background-color: #f5f5f5; */
        background-color:#f3f1f2;
       /* margin-bottom: 25px; */
        padding: 2rem;
        border-radius: 0.5rem;
        box-shadow: 0 0.5rem 1rem rgba(116, 112, 112, 0.3);
        z-index: 1000;
        text-align: center;
       
      }

      .alert-box h2 {
        color: white;
        margin-top: 1rem;
        font-size: 1.5rem;
        line-height: 2.5rem;
      }


      .ticket-alert-box {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color:rgb(11, 8, 98);
        padding: 2rem;
        border-radius: 0.5rem;
        box-shadow: 0 0.5rem 1rem rgba(116, 112, 112, 0.3);
        z-index: 1000;
        text-align: center;
       
      }

      .ticket-alert-box h2 {
        color: white;
        margin-top: 1rem;
        font-size: 1.5rem;
        line-height: 2.5rem;
      }
      .flex{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    
      
      .alert-box-delivery-list {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 90%;
        transform: translate(-50%, -50%);
        /* background-color: #f5f5f5; */
        background-color:#f4f4f4;
        padding: 2rem;
        border-radius: 0.5rem;
        box-shadow: 0 0.5rem 1rem rgba(116, 112, 112, 0.3);
        z-index: 1000;
        text-align: center;
       
      }

      /* ///text editor  styles*/

      body {
        background: #f3f1f2;
        font-family: sans-serif;
      }
      
      /* #root {
        margin: 1rem 4rem;
      } */
      
      #root .ql-container {
        width: 100%;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        background: #fefcfc;
      }
      
      /* Snow Theme */
      #root .ql-snow.ql-toolbar {
        display: block;
        width: 100%;
        height: auto;
        background: #eaecec;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
      }
      
      #root .ql-editor {
        min-height: 20em;
        display: block;
      }
      
      .hr_lineStyle{
        margin: 5px 0px 15px 0 !important;
        color: #bababa !important;
        
        }

        /* toggle button  */

        .switch {
          position: relative;
          display: inline-block;
          width: 60px;
          height: 34px;
        }
        
        .switch input { 
          opacity: 0;
          width: 0;
          height: 0;
        }
        
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: .4s;
          transition: .4s;
        }
        
        .slider:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
        }
        
        input:checked + .slider {
          background-color: #2196F3;
        }
        
        input:focus + .slider {
          box-shadow: 0 0 1px #2196F3;
        }
        
        input:checked + .slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
        
        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
        }
        
        .slider.round:before {
          border-radius: 50%;
        }    

        .qr-container {
          position: relative;
          width: 200px;
          height: 250px;
        }
        
        .qr-download-link {
          position: absolute;
         
          bottom: 0;
          left: 0;
          width: 100%;
         
          background-color: #eaeaea;
          padding: 10px;
          text-align: center;
          text-decoration: none;
          color: #000;
        }
        .align_center{
          align-items: center;
        }

        .bold{
          font-weight:bold
        }
        .diplay_none{
          display: none !important;
        }

     
        @media only screen and (max-width: 600px) {
        
          /* .d-none{
            display: block;
          } */
          .block_phone{
            display: block !important;
          }
        
.all-content{
  margin: 20px !important;
}
          .diplay_none{
            display: block !important;
          }

          .d-none-phone{
            display: none !important ;
          }
          .text_Align_Left{
text-align: left !important;
          }

          .tab{
            font-size: 14px;
            padding:8px 11px
          }
          .admin-dis-chat{
            padding: 10px !important;
          }
          .tab-content{
            padding:20px 10px !important;
          }
          .btn_phone{
            padding: 8px 10px !important;
          }

          .p-4{
            padding:0rem !important;
          }
          .px-4{
            padding-left: 2px !important;
            padding-right: 2px !important;
          }
      .font_16{
        font-size: 16px !important ;
      }
      .weight_600{
        font-weight: 600;
      }
      .font_16{
        font-size: 16px !important;
      }
      
        }
        .ul_List ul{
       list-style-type: none;
       padding-left: 0;
       
        }
/* Style the dropdown container */
.nav-item.dropdown {
  position: relative;
  display: inline-block;
}

/* Style the dropdown menu, hidden by default */
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Style the dropdown items */
.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu when hovering over dropdown container */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

/* Optional: Style the dropdown toggle */
.nav-link.dropdown-toggle {
  cursor: pointer;
}


table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.costOrder_Style{
  display: flex;
  justify-content: space-between;
  align-items: center;
  }

  .alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* background-color: rgba(244, 238, 238, 0.5); */
    z-index: 999;
  }


  
  
  .alert-box {
    position: fixed;
    top: 50%;
    left: 50%;
    width: auto;
   
    transform: translate(-50%, -50%);
    /* background-color: #f5f5f5; */
    background-color:rgb(22, 60, 99);
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(116, 112, 112, 0.3);
    z-index: 1000;
    text-align: center;
   
  }

  .tags-input {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    padding: 4px;
}

.tag {
    background-color: #333;
    color: #fff;
    margin: 2px;
    padding: 2px 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.tag button {
    background: none;
    border: none;
    color: #fff;
    margin-left: 4px;
    cursor: pointer;
}

.product-tag-container {
  background-color: rgb(232, 227, 227);
  border-radius: 20px; /* Adjust as needed for the desired roundness */
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
  color: rgb(18, 18, 44);
  font-family: 'Arial', sans-serif; /* Or any sans-serif font you prefer */
  font-size: 16px; /* Adjust as needed */
  font-weight: bold;
}

.logo-circle {
  background-color: red;
  height: 20px; /* Adjust as needed */
  width: 20px; /* Adjust as needed */
  border-radius: 50%; /* This makes it a circle */
  margin-right: 10px; /* Adjust as needed */
}

/* Adjustments for the text */
.product-tag-text {
  display: block;
}
@media screen and (max-width: 700px){
  .font10px{
    font-size: 10px !important;
  }
}
.highlightedrow {
  background: rgb(24, 1, 61) !important;
  color: white !important;
}



.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0a084e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0a084e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

